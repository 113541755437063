@font-face {
    font-family: 'apple';
    src: local('MyFont'), url(../static/fonts/AppleChancery.ttf) format('woff');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
  }

  @font-face {
    font-family: 'snell';
    src: local('MyFont'), url(../static/fonts/snell-roundhand/SnellBT-Regular.otf) format('woff');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
  }

.naslov {
    color: rgb(182, 96, 96);
    font-weight: bolder;
    font-family: snell;                    
    font-size: 450%;
}

.naslov-sobe {
    color: rgb(0, 0, 0);
    font-family: snell;                    
    font-size: 300%;
}
.prikaz-soba {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    flex-direction: row;
    max-height: 100vh;
}

.container {
    position: relative;
    width: 50%;
  }
    
  .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    background-color: #dddddd;
  }
  
  .container:hover .overlay {
    opacity: 0.7;
  }
  
  .text {
    color: rgb(0, 0, 0);
    font-size: 20px;
    position: absolute;
    top: 20%;
    left: 40%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: left;
  }


.soba {
    margin: 10px;
    border: 3px solid #000;
    box-shadow: 3px 3px 8px 0px rgba(0,0,0,0.3); 
    max-width: 23vw;
}

.rezerviraj {
    background-color: black;
    font-weight: bold;
    width: 50%;
    align-items: center;
    color: white;
    font-size: larger;
}

@media (max-width: 1000px) {

.naslov {
    color: rgb(182, 96, 96);
    font-weight: bolder;
    font-family: snell;                    
    font-size: 300%;
}

.naslov-sobe {
    color: rgb(0, 0, 0);
    font-family: snell;                    
    font-size: 250%;
}
.prikaz-soba {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    flex-direction: row;
    max-height: 100vh;
}

.container {
    position: relative;
    width: 50%;
  }
    
  .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    background-color: #dddddd;
  }
  
  .container:hover .overlay {
    opacity: 0.7;
  }
  
  .text {
    color: rgb(0, 0, 0);
    font-size: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
  }


.soba {
    margin: 10px;
    border: 3px solid #000;
    box-shadow: 3px 3px 8px 0px rgba(0,0,0,0.3); 
    max-width: 70vw;
}

.rezerviraj {
    background-color: rgb(230, 11, 11);
    font-weight: bold;
    width: 50%;
    align-items: center;
    color: white;
}
}