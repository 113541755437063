.img-container200{
    width: 150px;
    height: 230px;
}

.text-large {
    font-size: larger;
}

#cover {
    opacity:0.93;
    background-color:rgb(194, 189, 189);
    position:fixed;
    width:100%;
    height:100%;
    top:0px;
    left:0px;
    z-index:1000;
    display : none;
}

.center {
    margin-top: 30%;
    font-weight: bolder;
    font-size: xx-large;
    text-align: center;
}

#dataneeded {
    display: none;
}

#datanotneeded {
    display: none;
}

#inputcheckindiv{
    display: none;
}

.displaynone {
    display: none;
}

.flex-container{
    display: flex;
        flex-wrap: wrap;
        justify-content:space-around;
        width: 70%;
        padding-left: 30%;
        flex-direction: column;
        max-height: 150vh;
}

.error {
    color: red;
}

@media (max-width: 1000px) {
    .flex-container{
        display: flex;
            flex-wrap: wrap;
            justify-content:space-around;
            width: 100%;
            padding-left: 0%;
            flex-direction: column;
            max-height: 150vh;
    }

}