.bgc-yellow {
    background-color: rgb(240, 192, 104);
}

.left {
    float: inline-start;
}
.flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}



