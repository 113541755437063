@font-face {
    font-family: 'apple';
    src: local('MyFont'), url(../static/fonts/AppleChancery.ttf) format('woff');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
  }

  @font-face {
    font-family: 'snell';
    src: local('MyFont'), url(../static/fonts/snell-roundhand/SnellBT-Regular.otf) format('woff');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
  }
  @font-face {
    font-family: 'caviar';
    src: local('MyFont'), url(../static/fonts/CaviarDreams_BoldItalic.ttf) format('woff');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
  }

  
.inputData {
    background-color: rgba(255, 255, 255, 0.914);
    box-shadow: 5px 5px black;
    width: 33%;
    border-top: 1px black solid;
    border-left: 1px black solid;
    padding-top: 1vw;
}

.labelInput {
    font-size: 1.25vw;
    font-family: caviar;
}
.podnaslov {
    font-family: apple;
    font-size: 2vw;
    text-decoration: underline;
    color: #cfa671;
}

input {
    font-size: 1vw;
    text-align: right;
}

.form-control{
    width: 60px;
    display: block;

}

.background {
    background-color: black;
}

.error {
    font-size: large;
    text-decoration: solid;
    color: rgb(151, 7, 7);
}

#rezervacija {
    background-color: white;
    background-image: url("../static/krem2.svg");
}

.centeredimagetext {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .containerimg {
    position: relative;
    text-align: center;
    color: red;
  }

  .slika {
    position: relative;
    width: 70%;
    height: 70%;
  }

  .cijena {
      font-size: x-large;
  }

  .Prikaz{
    display : flex;
    width: 60%;
    margin-left: 20%;
    margin-bottom: 5px;
  }

  .flex-column {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-around;
  }

  #potvrdeno {
    display: none;
    background-color: white;
  }
  #labelpay {
    display: none;
  }

  .labelform {
      font-size: larger;
    }

.inputdodatak {
    align-items: center;
    display: grid;
    justify-content: center;
}

.flex-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    flex-direction: row;
    max-height: 100vh;
    align-items: center;
}

.flex-container-special {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    flex-direction: row;
    max-height: 100vh;
    align-items: center;
}

.borderinput {
    border: 1px #cfa671 solid;
    padding: 15px;
}
.backgroundcolorDataCentered {
    background-color: #eaddcb;
    width: 40%;
    margin-left: 30%;
    border: 1px #cfa671 solid;
    padding: 15px;
}

.border {
    border: 4px solid black;
    box-shadow: 2px 2px black;
}

.cntrText {
  text-align: center;
}

.textlg {
  font-size: large;
  font-weight: bold;
}
.biggerselect {
  font-size: large;
}

.textXXlg {
  font-size: xx-large;
  font-weight: bold;
}

.margin5{
  margin: 0px;
}

.soldout {
  color: rgb(3, 87, 212);
}



#load {
  display: none;
  opacity: 0.50;
  align-items: center;
}
  
  @media (max-width: 1000px) {
    .flex-container-special {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        flex-direction: row;
        max-height: 100vh;
        align-items: center;
    }
    .inputData {
        background-color: rgba(255, 255, 255, 0.914);
        box-shadow: 5px 5px #1f1f1f;
        width: 80%;
        border-top: 1px black solid;
        border-left: 1px black solid;
        padding-top: 10vw;
    }
    
    .labelInput {
        font-size: 4vw;
        font-family: caviar;
    }
    .podnaslov {
        font-family: apple;
        font-size: 8vw;
        text-decoration: underline;
    }
    
    input {
        font-size: 1vw;
        text-align: right;
    }

    .backgroundcolorDataCentered {
      background-color: #eaddcb;
      width: 80%;
        margin-left: 10%;
        border: 1px black solid;
        padding: 15px;
    }

    .slika {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .cijena {
      font-size: x-large;
  }

  .Prikaz{
    display : flex;
    flex-wrap: wrap;
    width: 95%;
    margin-left: 2.5%;
    margin-top: 2.5%;
  }

  .flex-column {
    display: flex;
    flex-direction: row;
    width: 100%;
  }
}