#cloudbackground {
    background-color: white;
    background-image: url("../static/krem2.svg");
}

.backgroundcolorDataCenteredbigger {
    background-color: white;
    width: 40%;
    margin-left: 30%;
    border: 1px black solid;
    padding: 3vw;
}

#redbackground{
    background-color: rgb(230, 182, 86);
    padding: 5px;
}

.underline {
    text-decoration: underline;
}

.Redtext{
    color: red;
}

@media (max-width: 1000px) {

    .backgroundcolorDataCenteredbigger {
        background-color: white;
        width: 90%;
        margin-left: 5%;
        border: 1px black solid;
        padding: 3vw;
    }

}
