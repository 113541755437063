.centeredtable {
    width: 100%;
    align-self: center;
    border: 1px solid black;
}
.flex-center {
    display: flex;
    justify-content: space-evenly;

}

.centr {
    margin-left: 20%;
    margin-right: 20%;
    width: 60%;
}

@media (max-width: 1000px) {
    .centeredtable{
        width: 100%;
        align-self: center;
        border: 1px solid black;

    }
}