.curve {
    width: 10vw;
    height: 5vw;
    margin: 0%;
    border: 0ch;
}

.popustbanner {
    background-color: rgb(233, 192, 68);
    font-size: xx-large;
}

@media (max-width: 1000px) {
    .popustbanner {
        background-color: rgb(233, 192, 68);
        font-size: large;
    }
}